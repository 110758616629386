import React from 'react';
import PropTypes from 'prop-types';
import Styles from './GeneratedScheduleBanner.module.css';
import { DatePicker } from 'op2mise-react-widgets';
import {
  addDaysToDate,
  subtractDaysToDate,
} from 'utils';

function GeneratedScheduleBannerComponent({
  componentName,
  scheduleDetails,
  dateRange,
  callbackChangeDateRange,
  scheduleID,
}) {
  const getDate = (date) => {
    return date && new Date( date.getFullYear(), date.getMonth(), date.getDate()+1 );
  };
  return (
    <div className={`${Styles.header} position-relative`}>
      <b className={Styles.headerText}>{componentName}</b>
      <div className="d-flex position-absolute end-0">
        <div className={`row ${Styles.marginBottom}`}>
          <div className={Styles.divHeaderPadding}>
            <div className={Styles.channelLabel}>Channel:</div>
          </div>
          <div className={Styles.divHeaderPadding}>
            <strong className={Styles.channelLabel}>
              {scheduleDetails.channelName}
            </strong>
          </div>

          {scheduleID === '' || scheduleID === 0 ? (
            <>
{/*               
                <div className={Styles.divHeaderPadding}>
                <div className={Styles.channelLabel}>Period from:</div>
              </div> */}
              <div
                className={`
              ${Styles.divHeaderPadding} 
              ${Styles.paddingTopNone} 
              ${Styles.customDateInput}`}
                style={{ marginTop: '-6px', display:'flex', alignItems:'center' }}
              >
                {/* <DateRangePicker
                  startDate={dateRange.start}
                  endDate={dateRange.end}
                  onChange={callbackChangeDateRange}
                /> */}
                <DatePicker 
                  allowEdit={false}
                  cssClass="view-schedules-date-picker"
                  format={'dd/MM/yyyy'}
                  label="Period from:"
                  date={dateRange.start} 
                  max={subtractDaysToDate(dateRange.end, 1)}
                  onChange={(e)=> callbackChangeDateRange({ start: e.target.value, end: dateRange.end })} 
                  showClearButton={false}
                  placeholder="dd/MM/yyyy"
                  value={dateRange.start}
                />
                <DatePicker 
                  allowEdit={false}
                  cssClass="view-schedules-date-picker"
                  format={'dd/MM/yyyy'}
                  label="To:"
                  date={dateRange.end} 
                  min={addDaysToDate(dateRange.start, 1)}
                  onChange={(e) => callbackChangeDateRange({ start: dateRange.start, end: e.target.value })} 
                  showClearButton={false}
                  placeholder="dd/MM/yyyy"
                  value={dateRange.end}
                />
              </div>
            </>
          ) : (
            <>
              <div className={Styles.divHeaderPadding}>
                <div className={Styles.channelLabel}>Period:</div>
              </div>
              <div className={Styles.divHeaderPadding}>
                <strong className={Styles.channelLabel}>
                  {scheduleDetails.period}
                </strong>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
GeneratedScheduleBannerComponent.propTypes = {
  componentName: PropTypes.string.isRequired,
  scheduleDetails: PropTypes.shape(),
};
export default GeneratedScheduleBannerComponent;
