import { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import PropTypes from 'prop-types';
import './DatePicker.css';

export const DatePicker = (props) => {
  const {
    allowEdit,
    cssClass,
    format,
    label,
    ref,
    showClearButton,
    value,
    min,
    max,
    onChange,
    ...rest
  } = props;

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'flex-end',
        marginRight: '10px',
      }}
    >
      {label && (<div
        style={{
          fontSize: '14px',
          color: '#6D6D73',
          marginBottom: '5px',
          marginRight: '10px',
          whiteSpace: 'nowrap',
        }}
      >
        {label}
      </div>)}
      <div className='view-schedule-date-picker-wrapper'>
        <DatePickerComponent
          ref={ref}
          value={value}
          format={format}
          showClearButton={showClearButton}
          allowEdit={allowEdit}
          strictMode={true}
          min={min}
          max={max}
          onChange={onChange}
          cssClass={cssClass}
          {...rest}
        />
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  ref: PropTypes.object,
  value: PropTypes.instanceOf(Date),
  format: PropTypes.string,
  showClearButton: PropTypes.bool,
  allowEdit: PropTypes.bool,
  onChange: PropTypes.func,
  cssClass: PropTypes.string,
};
