import axios from 'axios';
import React, {
  useCallback,
  useEffect,
  useState,
  Suspense,
  useMemo,
} from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { useIdleTimer } from 'react-idle-timer';
import '@progress/kendo-theme-default/dist/all.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import './App.css';
import './AppFont.css';
import 'assets/css/op2mise-color-palettes.css';
import AuthService from './core/auth.service';
import StringService from './core/string.service';
import ViewsComponent from './views/views';
import EventType from './enums/EventTypeEnum';
import ViewsService from './views/views.service';
import packageJson from '../package.json';
global.appVersion = packageJson.version;
import useStore from 'store/AccountStore';
import { Modal } from 'react-bootstrap';
import Button from 'shared/components/button/Button';

const LoginComponent = React.lazy(() =>
  import('./components/pages/login/LoginComponent')
);
const ForgotPasswordComponent = React.lazy(() =>
  import('./components/forgot-password/ForgotPasswordComponent')
);
const PasswordResetComponent = React.lazy(() =>
  import('./components/password-reset/PasswordResetComponent')
);
const InternalServerComponent = React.lazy(() =>
  import('./components/_error-pages/internal-server/InternalServerComponent')
);
const AccessDeniedComponent = React.lazy(() =>
  import('./components/_error-pages/access-denied/AccessDeniedComponent')
);
const NotFoundComponent = React.lazy(() =>
  import('./components/_error-pages/not-found/NotFoundComponent')
);
const MaintenanceComponent = React.lazy(() =>
  import('./components/maintenance/MaintenanceComponent')
);

function App() {
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(
    AuthService.isAuthenticated
  );
  const { user, setUser } = useStore((state) => state);
  const userIdentity = user;
  const [isTimedOut, setIsTimedOut] = useState(false);
  const [toNavigate, setToNavigate] = useState('');
  const [showModalWarning, setShowModalWarning] = useState(false);
  const [countdown, setCountdown] = useState(59);
  const [wasAutoLoggedOut, setWasAutoLoggedOut] = useState(false);

  useEffect(() => {
    window.addEventListener('storage', (event) => {
      const credentials = JSON.parse(
        window.sessionStorage.getItem('userIdentity')
      );

      if (event.key === EventType.Request_Credentials && credentials) {
        window.localStorage.setItem(
          EventType.Share_Credentials,
          JSON.stringify(credentials)
        );
        window.localStorage.removeItem(EventType.Share_Credentials);
      }
      if (event.key === EventType.Share_Credentials && !credentials) {
        window.sessionStorage.setItem('userIdentity', event.newValue);
        setIsAuthenticated(true);
      }
      if (event.key === EventType.Flush_Credentials && credentials) {
        window.sessionStorage.removeItem('userIdentity');
        setIsAuthenticated(false);
      }
    });

    window.localStorage.setItem(
      EventType.Request_Credentials,
      Date.now().toString()
    );
    window.localStorage.removeItem(EventType.Request_Credentials);

    setIsAuthenticated(AuthService.isAuthenticated());
  }, []);

  useEffect(() => {
    if (Object.keys(user).length == 0 && isAuthenticated) {
      var userIdentity = AuthService.getUserIdentity();
      setUser(userIdentity);
    }
  }, [user]);

  const navigateToDefaultLanding = () => {
    const landingPage =
      userIdentity?.roleId === 1
        ? `${process.env.REACT_APP_SUBFOLDER}/organisation`
        : `${process.env.REACT_APP_SUBFOLDER}/channel-profiles`;
    setToNavigate(landingPage);
  };

  const handleGetUserLastAccessPage = async (userId) => {
    try {
      const response = await ViewsService.getUserLastAccess(userId);
      if (response?.data) {
        const lastAccessPage =
          response.data == '/scheduler'
            ? process.env.REACT_APP_SUBFOLDER + '/schedule'
            : process.env.REACT_APP_SUBFOLDER + response.data;
        setToNavigate(lastAccessPage);
      } else {
        navigateToDefaultLanding();
      }
    } catch (error) { }
  };

  const handleOnLogin = useCallback(async (userId) => {
    setIsAuthenticated(true);
    await handleGetUserLastAccessPage(userId);
  }, []);

  const callbackUserLogout = () => {
    AuthService.logout();
    setIsAuthenticated(false);
    setShowModalWarning(false);
  };

  const navigateToLogin = () => {
    navigate(`${process.env.REACT_APP_SUBFOLDER}/login`);
  };

  const goBackToSignin = () => {
    navigateToLogin();
    setWasAutoLoggedOut(false);
  };

  const onAction = () => {
    setIsTimedOut(false);
  };

  const onActive = () => {
    setIsTimedOut(false);
  };

  const onIdle = () => {
    if (isAuthenticated) {
      // set 'cancelModalTimer' storage item to false
      localStorage.setItem('cancelModalTimer', 'false');
      setIsTimedOut(true);
      setShowModalWarning(true);
    }
  };

  useIdleTimer({
    onIdle,
    onActive,
    onAction,
    timeout: 1740000,
    throttle: 500,
    crossTab: true,
    leaderElection: true,
    syncTimers: 200,
  });

  const worker = useMemo(() => {
    const sessionTimeoutWorker = new Worker(
      new URL('./hooks/countdownWorker.js', import.meta.url)
    );

    sessionTimeoutWorker.addEventListener('message', (event) => {
      const { countdown, timeout } = event.data;
      if (timeout) {
        AuthService.logout();
        callbackUserLogout();
        navigateToLogin();
        onCancelCountDown();
        setWasAutoLoggedOut(true);
        setIsTimedOut(false);
      } else {
        setCountdown(countdown);
      }
    });

    return sessionTimeoutWorker;
  }, []);

  useEffect(() => {
    if (isTimedOut) {
      worker.postMessage({ action: 'startCountdown' });
    }
  }, [isTimedOut, worker]);

  const onCancelCountDown = () => {
    worker.postMessage({ action: 'stopCountdown' });
    setShowModalWarning(false);
    setCountdown(59);
    // set 'cancelModalTimer' storage item to true
    localStorage.setItem('cancelModalTimer', 'true');
  };

  useEffect(() => {
    const handleStorageChange = () => {
      // get value from local storage item
      const cancelModalTimer = JSON.parse(
        localStorage.getItem('cancelModalTimer')
      );
      if (cancelModalTimer) {
        // get most up-to-date value from state
        setShowModalWarning((prevShowModalWarning) => {
          if (prevShowModalWarning) {
            // cancel countdown when value is true
            onCancelCountDown();
          }
          // return up-to-date value as current state
          return prevShowModalWarning;
        });
      }
    };

    const handlePageBack = () => {
      onCancelCountDown();
    };

    const handlePageUnload = () => {
      onCancelCountDown();
      // remove local storage item when page is closed
      localStorage.removeItem('cancelModalTimer');
    };

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('popstate', handlePageBack);
    window.addEventListener('beforeunload', handlePageUnload);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('popstate', handlePageBack);
      window.removeEventListener('beforeunload', handlePageUnload);
    };
  }, [onCancelCountDown]);

  axios.interceptors.request.use(
    (config) => {
      const token = AuthService.getToken();
      if (token) {
        // eslint-disable-next-line
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      console.clear();
      Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response) {
        const { response } = error;
        if (response.data) {
          const { data } = error.response;
          if (data.statusCode === 401) {
            return Promise.reject(error);
          } else if (error.response.status === 403) {
            window.location.href = `${process.env.REACT_APP_SUBFOLDER}/access-denied`;
          } else if (data.status === 500 || data.statusCode === 500) {
            window.location.href = `${process.env.REACT_APP_SUBFOLDER}/internal-server/${data.message}`;
          } else if (data.statusCode === 404) {
            window.location.href = `${process.env.REACT_APP_SUBFOLDER}/not-found`;
          } else if (data.status === 408 || data.statusCode === 408) {
            window.location.href = `${process.env.REACT_APP_SUBFOLDER}/request-timed-out`;
          } else return Promise.reject(error);
        }
      }
      console.clear();
      return false;
    }
  );

  const sessionExpiringNotificationModal = useMemo(() => {
    return (
      <Modal show={showModalWarning} centered>
        <Modal.Body className="text-center p-4">
          <b style={{ fontSize: '16px' }}>Session will expire!</b>
          <p style={{ fontSize: '13px', marginTop: '10px' }}>
            You will be automatically logged out for security reason in:
          </p>
          <b style={{ fontSize: '20px' }}>{`0:${StringService.padLeft(
            countdown.toString(),
            '0',
            2
          )}`}</b>
          <p style={{ fontSize: '13px', marginTop: '10px' }}>
            Would you like to keep working?
          </p>

          <Button
            text={'Keep working'}
            onClick={onCancelCountDown}
            style={{ marginTop: '5px', marginBottom: '5px' }}
          />
        </Modal.Body>
      </Modal>
    );
  }, [showModalWarning, countdown, onCancelCountDown]);

  const sessionTimeoutNotificationModal = useMemo(() => {
    return (
      <Modal show={wasAutoLoggedOut} centered>
        <Modal.Body className="text-center p-4">
          <p
            style={{
              fontSize: '16px',
              marginTop: '20px',
              marginBottom: '30px',
            }}
          >
            You were automatically logged out due to inactivity.
          </p>

          <Button
            text={'Sign in'}
            onClick={goBackToSignin}
            style={{ marginBottom: '5px' }}
          />
        </Modal.Body>
      </Modal>
    );
  }, [wasAutoLoggedOut, goBackToSignin]);

  const refreshCacheAndReload = () => {
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    // delete browser cache and hard reload
    window.location.reload(true);
  };

  const callBackGetVersion = useCallback(() => {
    try {
      axios.get(`/meta.json?timestamp=${new Date().getTime()}`).then((meta) => {
        const latestVersion = meta.data.version;
        const currentVersion = global.appVersion;
        if (latestVersion !== currentVersion) refreshCacheAndReload();
      });
    } catch (error) { }
  }, []);

  return (
    <div className="App">
      {sessionExpiringNotificationModal}
      {sessionTimeoutNotificationModal}
      {isAuthenticated ? (
        <>
          <ViewsComponent
            toNavigate={toNavigate}
            callbackUserLogout={callbackUserLogout}
            callBackGetVersion={callBackGetVersion}
            isAuthenticated={isAuthenticated}
          />
        </>
      ) : (
        <Routes>
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/login`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <LoginComponent onLogin={handleOnLogin} />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/internal-server`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <InternalServerComponent />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/access-denied`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <AccessDeniedComponent />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/not-found`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <NotFoundComponent />
              </Suspense>
            }
          />
          <Route
            path="*"
            element={
              <Navigate to={`${process.env.REACT_APP_SUBFOLDER}/login`} />
            }
          />
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/forgot-password`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <ForgotPasswordComponent />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/passwordreset/:urlParamId`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <PasswordResetComponent />
              </Suspense>
            }
          />
          <Route
            path={`${process.env.REACT_APP_SUBFOLDER}/maintenance`}
            element={
              <Suspense fallback={<div>Loading</div>}>
                <MaintenanceComponent />
              </Suspense>
            }
          />
        </Routes>
      )}
    </div>
  );
}

export default App;
