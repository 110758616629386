import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';

function Input({
  ref,
  label,
  name,
  type,
  placeholder,
  value,
  errorMessage,
  maxLength,
  isRequired,
  width,
  onChange,
  onFocus,
  onBlur,
  onClick,
  disabled,
  pattern,
  style,
  textAlign,
}) {

  const allowOneDecimal = (e, val) => {
    const regExp = new RegExp('^\\d{0,2}\\.[0-9]{1}$');
  
    if (!regExp.test(e.target.value)) {
      e.target.value = val.toString().match(/^-?\d+(?:\.\d{0,1})?/)
        ? val.toString().match(/^-?\d+(?:\.\d{0,1})?/)[0]
        : '';
    }
    return e.target.value;
  };
  
  const allowOnePoint = (val) => {
    if (val.includes('.')) {
      const valSplit = val.split('.');
      // eslint-disable-next-line no-param-reassign
      if (valSplit[0] === '') val = `0${val}`;
    }
    return val;
  };

  const getDecimalNumbersOnly = (e) => {
    let val = e.target.value;

    if (val.length > 1) val = val.replace(/^0+/, '');
    
    val = val.replace('..', '.');

    if (val.includes('.'))  {
      const valSplit = val.split('.');
      if (valSplit[0] === '') val = `0${val}`;
    }

    e.target.value = val.replace(/^00+/, 0);
    e.target.value = allowOnePoint(allowOneDecimal(e, val));
    return e.target.value;
  };

  const getNumbersOnly = (string) => {
    const stringArray = string.split('');
    const result = stringArray.filter((char) => {
      return !isNaN(char) && !isNaN(parseFloat(char));
    });
    return result.join('');
  };

  const handleOnChange = (e) => {
    let data = e;

    if (type === 'number') data.target.value = getNumbersOnly(e.target.value);
    if (type === 'decimal') {
      const decimalResult = getDecimalNumbersOnly(e);
      const maxValue = 100;
      if (decimalResult > maxValue) return;
      if (decimalResult) data.target.value = decimalResult;
    }

    if (typeof onChange === 'function') onChange(data); 
  };

  const handleOnBlur = (e) => {
    if (typeof onChange === 'function') {
      e.target.value = e.target.value.replace(/\s+/g, ' ').trim();
      onChange(e);
    }
    if (typeof onBlur === 'function') onBlur();
  };

  return (
    <>
      {label && (
        <div className={styles.label}>
          {label} {isRequired && <span style={{ color: '#FF0000' }}>*</span>}
        </div>
      )}

      <input
        ref={ref}
        className={`${styles.input} ${errorMessage && styles.errorInput}`}
        style={{ width, ...style, textAlign: textAlign }}
        name={name}
        type={type === 'number' ? 'text' : type}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        autoComplete="off"
        onChange={(e) => handleOnChange(e)}
        onFocus={onFocus}
        onBlur={handleOnBlur}
        disabled={disabled}
        pattern={pattern}
        onClick={onClick}
      />

      {errorMessage && <small className={styles.errorMessage}>{errorMessage}</small>}
    </>
  );
}

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  maxLength: PropTypes.string,
  isRequired: PropTypes.bool,
  width: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.shape(PropTypes.any),
  onClick: PropTypes.func,
  textAlign: PropTypes.string,
};

Input.defaultProps = {
  label: null,
  name: null,
  type: 'text',
  placeholder: null,
  value: null,
  errorMessage: null,
  maxLength: null,
  isRequired: false,
  width: null,
  onChange: null,
  onFocus: null,
  onBlur: null,
  disabled: false,
  style: {},
  onClick: null,
  textAlign: 'start',
};

export default Input;
