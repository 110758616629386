import { Internationalization } from '@syncfusion/ej2-base';
import './QuickInfoTemplate.css';

export const QuickInfoTemplate = (props) => {
  const {
    State,
    StartTime,
    EndTime,
    Duration,
    Genre,
    Grade,
    Certificate,
    IsLive,
    IsFixed,
  } = props;
  
  /** Converts the date value and extracts the time */
  let instance = new Internationalization();

  const getTimeString = (value) => {
    if (State && State.timeFormat) {
      return instance.formatDate(value, {type: 'dateTime', format: `dd/MM/yyyy ${State.timeFormat}`});
    }
    return null;
  };

  /** Converts the time value string into minutes: '02:00' -> (120 minutes) */
  const convertDurationToMinutes = (timeString) => {
    if (timeString) {
      // Split the time string into hours and minutes
      const parts = timeString.split(':');
      // Convert hours to minutes and add minutes
      return `${parseInt(parts[0]) * 60 + parseInt(parts[1])} minutes`;
    }
    return null;
  };

  return (
    <div className='e-schedule-custom-quick-info'>
      <div className='e-schedule-custom-quick-info-row'>
        <span style={{flex: 1}}><strong>Schedule: </strong></span>
        <span style={{flex: 3}}>{getTimeString(StartTime)} - {getTimeString(EndTime)}</span>
      </div>
      <div className='e-schedule-custom-quick-info-row'>
        <span style={{flex: 1}}><strong>Duration: </strong></span>
        <span style={{flex: 3}}>{convertDurationToMinutes(Duration)}</span>
      </div>
      <div className='e-schedule-custom-quick-info-row'>
        <span style={{flex: 1}}><strong>Genre: </strong></span>
        <span style={{flex: 3}}>{Genre}</span>
      </div>
      <div className='e-schedule-custom-quick-info-row'>
        <span style={{flex: 1}}><strong>Grade: </strong></span>
        <span style={{flex: 3}}>{Grade}</span>
      </div>
      <div className='e-schedule-custom-quick-info-row'>
        <span style={{flex: 1}}><strong>Certificate: </strong></span>
        <span style={{flex: 3}}>{Certificate}</span>
      </div>
      <div className='e-schedule-custom-quick-info-row'>
        <div style={{flex: 1}}>
          <span style={{marginRight: '4px'}}><strong>Fixed: </strong></span>
          <span>{IsFixed ? 'Yes' : 'No'}</span>
        </div>
        <div style={{flex: 3}}>
          <span style={{marginRight: '4px'}}><strong>Live: </strong></span>
          <span>{IsLive ? 'Yes' : 'No'}</span>
        </div>
      </div>
    </div>
  );
}