import * as React from 'react';
import { Internationalization } from '@syncfusion/ej2-base';
import moment from 'moment';
import { GetChannelArchitectureAPI, ImportChannelArchitectureAPI } from 'api';
import LinkIcon from 'assets/icons/channel-architecture-linked-slot.svg';
import SequentialIcon from 'assets/icons/channel-architecture-linked-slot-sequence.svg';
import BootstrapSpinner from 'shared/components/bootstrap-spinner/BootstrapSpinner';
import { Scheduler } from 'op2mise-react-widgets';
import { getWeekNumber, formatDateWithMoment, addDaysToDate } from 'utils';
import { ImportComponent } from './modals/ImportComponent';
import { useEffect } from 'react';

function CAProgrammmes({
  schedulerProps, startDate, endDate, channelInfo, calculateDates, numberOfWeeks, setNumberOfWeeks
}) {
  const [openForm, setOpenForm] = React.useState(true);
  const [isDirty, setIsDirty] = React.useState(false);
  const { channelId } = channelInfo;
  const counter = React.useRef(0);

  // Getting date range of today and tomorrow
  // NOTE: Use addDaysToDate(x, y) from date.utils
  const tomorrow = moment()
    .add(1, 'days')
    .toDate();

  const period = `${formatDateWithMoment(
    new Date().toDateString(),
  )} - ${formatDateWithMoment(tomorrow)}`;

  const [schedules, setSchedules] = React.useState([{}]);
  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    counter.current = 0;
 }, [])

  const getEndDate = () => {
    return (numberOfWeeks > 1 ? addDaysToDate(startDate, numberOfWeeks * 7) : endDate)};

  const dateHeaderTemplate = React.useCallback((args) => {
    const instance = new Internationalization();
    const day = instance.formatDate(args.date, { skeleton: 'E' });
    const weekNumber = getWeekNumber(args.date, startDate, getEndDate());

    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="e-header-day" style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>{`WK${weekNumber}, ${day}`}</div>
      </div>
    );
  }, [startDate, endDate, numberOfWeeks]);

  const eventFields = (args) => ({
    Id: args.id,
    Subject: args.type,
    StartTime: calculateDates(args.dayOfWeek, args.week, args.startTime, args.endTime, {startDate, endDate})['startTime'], // Field required: Always convert to new Date(...)
    EndTime: calculateDates(args.dayOfWeek, args.week, args.startTime, args.endTime, {startDate, endDate})['endTime'], // Field required: Always convert to new Date(...)
    IsLinked: args.link,
    IsSequential: args.sequential,
    BlockReference: args.blockReference,
    TitleGroupName: args.titleGroupName,
  });

  const eventTemplate = (args) => {
    const { State, StartTime, EndTime, Subject, IsSequential, IsLinked, BlockReference, TitleGroupName } = args;
    /** Converts the date value and extracts the time */
    let instance = new Internationalization();
    const getTimeString = (value) => {
      return instance.formatDate(value, { skeleton: 'hm', format: State.timeFormat });
    };

    return (
      <div className='e-schedule-custom-event'>
        <div className='e-schedule-custom-event-title'>{Subject === 'TitleGroup' ? TitleGroupName : Subject}</div>
        <div className='e-schedule-custom-event-sub-title'>
          <div className='e-schedule-custom-event-sub-title-schedule-duration'>{`${getTimeString(StartTime)} - ${getTimeString(EndTime)}`}</div>
        </div>
        {(IsLinked || IsSequential) && (
          <div style={{position: 'absolute', bottom: 0, marginBottom: '4px', display: 'flex', flexDirection: 'row'}}>
            {/* {IsSequential && ( <img className='icon-link-sequential' src={SequentialIcon} alt='sequential-icon' width={16} height={16}/> )} */}
            {IsLinked && (
              <div style={{display: 'flex', flexDirection: 'row', width:'100%'}}>
                <img className='icon-link-sequential' src={LinkIcon} alt='link-icon' width={16} height={16}/>
                <span style={{color: 'rgba(250, 250, 250, 0.5)', marginLeft: '1px', overflow:'hidden', whiteSpace:'nowrap', textOverflow:'ellipsis', width: '80%' }}>{`${BlockReference} (${Subject})`}</span>
              </div>
            )}
          </div>
        )}
      </div>
    )
  }

  const getMaxWeek = (data) => {
    let maxWeek = 0;
    data.map((programme) => {
      if (programme.week > maxWeek) {
        maxWeek = programme.week;
      }
    });
    setNumberOfWeeks(maxWeek);
  }

  const programmeSchedulerProps = React.useMemo(() => ({
    ...schedulerProps,
    dataSource: schedules,
    eventLookupList: 'programmes',
    loading: isLoading,
    schedule: { start: startDate, end: getEndDate()},
    suppressExporting: true,
    suppressQuickInfo: true,
    suppressContextMenu: true,
    suppressTooltip: true,
    dateHeaderTemplate,
    eventFields,
    eventTemplate,
    onImport: () => {
      // Put your import logic here
      setOpenForm('IMPORT');
    },
    settings: ['SLOT DURATION', 'SLOT INTERVAL'],
    timeoutDelay: 800,
  }), [schedules, startDate, endDate, numberOfWeeks]);

  React.useEffect(() => {
    if (channelId || isDirty && counter.current === 0) {
      GetChannelArchitectureAPI({
        queryParams: { channelId },
        onSuccess: (response) => {
          setSchedules({ programmes: response });
          getMaxWeek(response);
          setIsDirty(false);
          counter.current += 1;
        },
        setLoader: setIsLoading,
      })
    }
  }, [channelId, isDirty]);

  const RenderScheduler = React.useCallback(() => {
    return <Scheduler {...programmeSchedulerProps} />

  }, [programmeSchedulerProps]);

  return (
    <>
      {openForm === 'IMPORT' && (
        <ImportComponent
          closeModal={() => setOpenForm('')}
          setIsDirty={setIsDirty}
          scheduleInfo={{ ...channelInfo, period }}
          schedulerData={{
            scheduleInfo: {
              ...channelInfo,
              period,
            },
          }}
          sampleTemplate="programmeImportTemplate"
          handleOnImportApi={ImportChannelArchitectureAPI}
          importResultColumns={[{
            field: 'week',
            headerText: 'Week',
          }, {
            field: 'dayOfWeek',
            headerText: 'Day of Week',
          }, {
            field: 'startTime',
            headerText: 'Start Time',
          }, {
            field: 'endTime',
            headerText: 'End Time',
          }, {
            field: 'type',
            headerText: 'Type',
          }, {
            field: 'genre',
            headerText: 'Genre',
          }]}
          importResultFileTitle="programme_import_result"
        />
      )}
      { isLoading
        ? <div style={{ height: 'calc(100vh - 100px)' }}><BootstrapSpinner /></div>
        :  <RenderScheduler />}
    </>
  );
}

export default CAProgrammmes;
